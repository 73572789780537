import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthGuard} from '@core/guards/auth.guard'
import {RoleCode} from '@core/schemes/role'
import {hasRolesForRoot} from '@core/utils/guard'

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () => import('./features/navigator/navigator.component').then(c => c.NavigatorComponent),
  },
  {
    path: 'klasses',
    loadChildren: () => import('./features/klasses/klasses.module').then(m => m.KlassesModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'content',
    loadChildren: () => import('./features/content/content.module').then(m => m.ContentModule),
    ...hasRolesForRoot(RoleCode.Admin, RoleCode.Teacher, RoleCode.SubjectLeader),
  },
  {
    path: 'plans',
    loadChildren: () => import('@features/plans/plans.module').then(m => m.PlansModule),
    ...hasRolesForRoot(RoleCode.Admin, RoleCode.Teacher),
  },
  {
    path: 'subject-groups',
    loadChildren: () => import('./features/subject-group/subject-group.module').then(m => m.SubjectGroupModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'staff',
    loadChildren: () => import('./features/staff/staff.module').then(m => m.StaffModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'school',
    loadChildren: () => import('./features/school/school.module').then(m => m.SchoolModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'notification-campaigns',
    loadChildren: () => import('./features/notification-campaign/notification-campaign.module').then(m => m.NotificationCampaignModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'my-lessons',
    loadChildren: () => import('./features/my-lessons/my-lessons.module').then(m => m.MyLessonsModule),
    ...hasRolesForRoot(RoleCode.Teacher),
  },
  {
    path: 'my-groups',
    loadChildren: () => import('./features/my-groups/my-groups.module').then(m => m.MyGroupsModule),
    ...hasRolesForRoot(RoleCode.Teacher),
  },
  {
    path: 'reports',
    loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'parents',
    loadChildren: () => import('./features/parents/parents.module').then(m => m.ParentsModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'transfer',
    loadChildren: () => import('./features/transfer/transfer.module').then(m => m.TransferModule),
    ...hasRolesForRoot(RoleCode.Admin),
  },
  {
    path: 'auth',
    loadComponent: () => import('./features/auth/auth.component').then(c => c.AuthComponent),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
