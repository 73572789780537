import {AfterViewInit, Component} from '@angular/core'
import {NavigationEnd, Router} from '@angular/router'
import {animate, style, transition, trigger} from '@angular/animations'
import {filter, map, Observable, of, switchMap} from 'rxjs'

import {AuthService} from '@core/services/http/auth.service'
import {SidebarService} from '@core/services/ui/sidebar.service'
import {RoleCode} from '@core/schemes/role'
import {UserService} from '@core/services/http/user.service'
import {School} from '@core/schemes/school'
import {EduYearService} from '@core/services/http/edu-year.service'
import {KlassService} from '@core/services/http/klass.service'
import {KlassStatus} from '@core/constants/klass-status'
import {MenuBook} from '@core/svg/menu-book'

interface MenuItem {
  icon?: string
  svg?: string
  name: string
  roleLinkMap: Partial<Record<RoleCode, string>>
  disabled?: boolean
  visibility?: Observable<boolean>
}

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeMenuItem', [
      transition(':enter', [
        style({
          opacity: 0,
          maxHeight: 0,
        }),
        animate('200ms ease-out', style({
          opacity: 1,
          maxHeight: 50,
        })),
      ]),
    ]),
  ],
})
export class SidebarComponent implements AfterViewInit {

  menuItems: MenuItem[] = [
    {
      icon: 'ji ji-menu-all-classes',
      name: 'Все классы',
      roleLinkMap: {
        [RoleCode.Admin]: '/klasses',
      },
    },
    {
      icon: 'ji ji-menu-all-classes',
      name: 'Перевод на след. год',
      roleLinkMap: {
        [RoleCode.Admin]: '/transfer',
      },
      visibility: this.eduYearService.getPrevEduYearId().pipe(
        switchMap(eduYearId => {
          const params: any = {status: KlassStatus.TransferWait}
          if (eduYearId) params.edu_year_id = eduYearId

          return this.klassService.listKlasses(params).pipe(
            map(klasses => klasses.length > 0),
          )
        }),
      ),
    },
    {
      icon: 'ji ji-menu-content',
      name: 'Контент',
      roleLinkMap: {
        [RoleCode.Admin]: '/content',
        [RoleCode.Teacher]: '/content/edu-plan',
        [RoleCode.SubjectLeader]: '/content',
      },
    },
    {
      svg: MenuBook,
      name: 'Учебные планы',
      roleLinkMap: {
        [RoleCode.Admin]: '/plans',
        [RoleCode.Teacher]: '/plans',
      },
    },
    {
      icon: 'ji ji-menu-subject-groups',
      name: 'Группы предмета',
      roleLinkMap: {
        [RoleCode.Admin]: '/subject-groups',
      },
    },
    {
      icon: 'ji ji-menu-personal',
      name: 'Персонал',
      roleLinkMap: {
        [RoleCode.Admin]: '/staff',
      },
    },
    {
      icon: 'ji ji-parents',
      name: 'Родители',
      roleLinkMap: {
        [RoleCode.Admin]: '/parents',
      },
    },
    {
      icon: 'ji ji-menu-school',
      name: 'Школа',
      roleLinkMap: {
        [RoleCode.Admin]: '/school',
      },
    },
    {
      icon: 'ji ji-ring',
      name: 'Уведомления',
      roleLinkMap: {
        [RoleCode.Admin]: '/notification-campaigns',
      },
    },
    {
      icon: 'ji ji-menu-schedule',
      name: 'Мои уроки',
      roleLinkMap: {
        [RoleCode.Teacher]: '/my-lessons',
      },
    },
    {
      icon: 'ji ji-menu-all-classes',
      name: 'Мои группы',
      roleLinkMap: {
        [RoleCode.Teacher]: '/my-groups',
      },
    },
    {
      icon: 'ji ji-menu-content',
      name: 'Статистика',
      roleLinkMap: {
        [RoleCode.Admin]: '/reports',
      },
    },
  ]

  currUrl = ''

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private sidebarService: SidebarService,
    private eduYearService: EduYearService,
    private klassService: KlassService,
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(e => {
        const {urlAfterRedirects} = e as NavigationEnd
        this.currUrl = urlAfterRedirects
      })
  }

  ngAfterViewInit() {
    this.sidebarService.init()
  }

  getRoles(item: MenuItem): RoleCode[] {
    return Object.keys(item.roleLinkMap) as RoleCode[]
  }

  isVisible(item: MenuItem): Observable<boolean> {
    return item.visibility ?? of(true)
  }

  getLink(item: MenuItem): string {
    const menuRoles = this.getRoles(item)
    const userRoles = this.userService.user.staff_member_profile.roles.filter(r => !r.effective_until_in || r.effective_until_in > 0)
    const role = menuRoles.find(role => userRoles.find(r => r.role === role))
    return role ? item.roleLinkMap[role] : '/'
  }

  isSelected(item: MenuItem): boolean {
    const link = Object.values(item.roleLinkMap)[0]
    return this.currUrl.startsWith(link)
  }

  toggleSidebar(): void {
    this.sidebarService.toggle()
  }

  logout(): void {
    this.authService.logout()
  }

  get showSidebar(): Observable<boolean> {
    return this.authService.hasUserData$
  }

  get school$(): Observable<School> {
    return this.userService.school$
  }
}
