import {Component} from '@angular/core'

import {AlertService} from '@core/services/ui/alert.service'

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent {

  constructor(
    public alertService: AlertService,
  ) {
  }
}
