import {Component} from '@angular/core'
import {Observable} from 'rxjs'

import {AuthService} from '@core/services/http/auth.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  constructor(
    public authService: AuthService,
  ) {
  }

  get showHeader$(): Observable<boolean> {
    return this.authService.hasUserData$
  }
}
